<template>
  <v-container grid-list-xl fluid>
    <listarCliente v-bind:titulo="'Confirma Pedido'" v-bind:busmodulo="{ busmodulo }"></listarCliente>
    <confirmarPedido></confirmarPedido>
  </v-container>
</template>

<script>
import clienteService from "@/services/ClienteServices";
import listarCliente from "@/components/cliente/ListarClientes.vue";
import confirmarPedido from "@/components/pedidos/confirmar.vue";

export default {
  name: "ConfirmarODTT",
  components: { listarCliente, confirmarPedido },
  busmodulo: "ConfirmarODTT",
  created() {
    this.cliente = clienteService.current();
    this.$bus.$on("SelectorClienteSetCliente_"+this.busmodulo, () => {
      this.cliente = clienteService.current();
    });
  },
};
</script>