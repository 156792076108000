<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn color="error" fab small dark v-on="on" title="Quitar Artículo">
        <v-icon>remove_shopping_cart</v-icon>
      </v-btn>
    </template>
    <v-card v-show="!isLoading && notificacion.mensaje < 1">
      <v-card-title>Eliminar artículo del pedido</v-card-title>
      <v-divider light></v-divider>
      <v-card-text>
        ¿Esta seguro de eliminar el artículo
        <b>{{ articulo.tmpot1_descri }}</b> del pedido?
      </v-card-text>
      <v-divider light></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="dialog = false"
          >Cancelar</v-btn
        >
        <v-btn color="primary darken-1" flat @click="eliminar()"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
    <alert
      v-bind:tipo="notificacion.tipo"
      v-bind:mensaje="notificacion.mensaje"
    ></alert>
    <loading v-bind:dialog="isLoading"></loading>
  </v-dialog>
</template>
<script>
import ODTTServices from "@/services/OrdenesTrabajoTemServices";
import loading from "@/components/share/Loading.vue";
import alert from "@/components/alert/alert.vue";
export default {
  name: "EliminarItem",
  components: { loading, alert },
  data() {
    return {
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      dialog: false
    };
  },
  props: {
    articulo: {
      type: Object,
      required: true
    }
  },
  methods: {
    eliminar() {
      this.isLoading = true;
      ODTTServices.deleteItem(this.articulo.tmpot1_item)
        .then(() => {
          this.notificacion.mensaje = "Artículo eliminado.";
          this.notificacion.tipo = "success";
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar eliminar el artículo.";
          this.notificacion.tipo = "error";
          if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
          setTimeout(() => {
            this.dialog = false;
            this.notificacion.mensaje = '';
            this.$bus.$emit("reload");
          }, 3000);
        });
    }
  }
};
</script>
