<template>
  <section>
    <loading v-bind:dialog="isLoading"></loading>
    <alert
      v-bind:tipo="notificacion.tipo"
      v-bind:mensaje="notificacion.mensaje"
    ></alert>
    <p v-if="notificacion.tipo == 'success'">
      Para consultar su pedido realizado haga click
      <v-btn color="primary" small outline :to="'/Pedidos/Consultar'" 
      >
        Aquí
      </v-btn>
    </p>
    <v-layout v-resize="onResize" column v-if="notificacion.mensaje == '' && pedido.items.length > 0">
      <v-flex xs12 sm12 lg12 xl12>
        <v-data-table
          :headers="tableheader"
          :items="pedido.items"
          :pagination.sync="pagination"
          :hide-headers="isMobile"
          :class="{ mobile: isMobile }"
          :rows-per-page-items="rowsperpageitems"
        >
          <template slot="items" slot-scope="props">
            <tr v-if="!isMobile">
              <td>{{ props.item.tmpot1_codart }}</td>
              <td>{{ props.item.tmpot1_cantid }}</td>
              <td>{{ props.item.tmpot1_descri }}</td>
              <td>
                <span v-if="props.item.precio_subtotal > 0">
                  {{ props.item.precio_subtotal | moneda }}
                </span>
                <span v-else>Consultar</span>
              </td>
              <!--<td>
                <span v-if="props.item.tmpot1_descue > 0">
                  {{ props.item.tmpot1_descue | moneda }}
                </span>
                <span v-else>-</span>
              </td>
              <td>
                <span v-if="props.item.tmpot1_precio > 0">
                  {{ props.item.tmpot1_subtotal | moneda }}
                </span>
                <span v-else>Consultar</span>
              </td>-->
              <td id="acciones" class="notbreak">
                <updateItem v-bind:articulo="props.item"></updateItem>
                <deleteItem v-bind:articulo="props.item"></deleteItem>
              </td>
            </tr>
            <tr v-else>
              <td>
                <ul class="flex-content">
                  <li class="flex-item" data-label="Cod.">
                    {{ props.item.tmpot1_codart }}
                  </li>
                  <li class="flex-item" data-label="Cantidad">
                    {{ props.item.tmpot1_cantid }}
                  </li>
                  <li class="flex-item" data-label="Art.">
                    {{ props.item.tmpot1_descri }}
                  </li>
                  <li class="flex-item" data-label="Precio U.">
                    <span v-if="props.item.tmpot1_precio > 0">
                      {{ props.item.tmpot1_precio | moneda }}
                    </span>
                    <span v-else>Consultar</span>
                  </li>
                  <!--<li class="flex-item" data-label="Desc. tot.">
                    <span v-if="props.item.tmpot1_descue > 0">
                      {{ props.item.tmpot1_descue | moneda }}
                    </span>
                    <span v-else>-</span>
                  </li>
                  <li class="flex-item" data-label="Subtotal">
                    <span v-if="props.item.tmpot1_precio > 0">
                      {{ props.item.subtotal | moneda }}
                    </span>
                    <span v-else>Consultar</span>
                  </li>-->
                  <li class="flex-item" data-label="Acciones">
                    <updateItem v-bind:articulo="props.item"></updateItem>
                    <deleteItem v-bind:articulo="props.item"></deleteItem>
                  </li>
                  <li class="enter"></li>
                </ul>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <tr>
              <td colspan="3" align="right" class="font-weight-bold">Total:</td>
              <td colspan="2">{{pedido.tmpot0_imptot | moneda }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <!-- form observaciones-->
    <v-layout wrap v-if="notificacion.mensaje == '' && pedido.items.length > 0">
      <v-flex xs12 sm12 lg12 xl12>
        <modObservacion v-bind:tmpot0_observ="pedido.tmpot0_observ"></modObservacion>
      </v-flex>
    </v-layout>

    <v-layout wrap v-if="notificacion.mensaje == '' && pedido.items.length > 0">
      <v-flex xs12 sm12 lg12 xl12>
        <v-card>
          <v-card-title primary-title>
            <div>
              <span class="headline">Finalizar el pedido</span>
              <br />
            </div>
          </v-card-title>


          <hr />
          <v-card-text class="pie-carta">
            <div>
              Solo resta un paso. Controle los productos y cantidades ingresadas en la lista y luego presione el botón 
              “Finalizar”
            </div>
          </v-card-text>
          <v-card-actions class="pie-carta">
            <v-layout>
              <v-flex xs12>
                <v-btn color="primary" small :to="'/Pedidos/Nuevo'">
                  Continuar agregando
                </v-btn>
                <v-btn small flat disabled class="ocultar-btn">
                  O
                </v-btn>
                <v-btn color="success" small @click="finalizar()">
                  Finalizar pedido
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import DataTableMixin from "@/mixins/DataTableMixin";
import OrdenesTrabajoTemServices from "@/services/OrdenesTrabajoTemServices";
import clienteService from "@/services/ClienteServices";
import loading from "@/components/share/Loading.vue";
import alert from "@/components/alert/alert.vue";
import deleteItem from "@/components/pedidos/deleteitem.vue";
import updateItem from "@/components/pedidos/updateitem.vue";
import modObservacion from "@/components/pedidos/modObservacion.vue";
import utils from "@/plugins/Utils.js";


export default {
  name: "confirmaPedido",
  mixins: [utils.mixins, DataTableMixin],
  components: { alert, loading, deleteItem, updateItem, modObservacion },
  data() {
    return {
      isMobile: false,
      pagination: { sortBy: "tmpot1_codart", descending: true },
      tableheader: [
        { text: "#", value: "tmpot1_codart" },
        { text: "Cant.", value: "tmpot1_cantid" },
        { text: "Artículo", value: "tmpot1_descri" },
        { text: "Precio", value: "precio_subtotal" },
        { text: "Acciones", value: "-" },
      ],
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      pedido: OrdenesTrabajoTemServices.ODTT,
    };
  },
  created() {
    this.cargar();
    this.$bus.$on("reload", () => {
      this.cargar();
    });
    this.$bus.$on("modObservacionSucess" ,(newObserv) =>{
      this.pedido.tmpot0_observ = newObserv;
    });
    this.$bus.$on("SelectorClienteSetCliente", () =>{
      this.$router.push('/CtaCte')
    });
  },
  methods: {
    cargar() {
      let curcliente = clienteService.current();
      if (curcliente.cli_codigo < 1) {
        this.notificacion.mensaje = "Seleccione un cliente para continuar";
        return false;
      }

      this.isLoading = true;
      OrdenesTrabajoTemServices.getById(curcliente.cli_codigo)
        .then((res) => {
          //console.log(res);
          this.pedido = res;
          if (this.pedido.items.length < 1) {
            this.$bus.$emit("cleanCar");
          }else{
            let aux = 0;

            for (let index = 0; index < res.items.length; index++) {
              aux += parseFloat(res.items[index].tmpot1_cantid);

              //res.items[index].subtotal = (res.items[index].tmpot1_cantid * res.items[index].tmpot1_precio) - res.items[index].tmpot1_descue
            }

            localStorage.cantidadItemsCarrito = parseFloat(aux);
            this.$bus.$emit("setCar");
          }
        })
        .catch((err) => {
          this.notificacion.tipo = "error";
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    finalizar() {
      this.isLoading = true;
      OrdenesTrabajoTemServices.confirmar(this.pedido.tmpot0_codigo)
        .then(() => {
          this.notificacion.mensaje = "Pedido realizado con éxito.";
          this.notificacion.tipo = "success";
          this.$bus.$emit("cleanCar");
          this.pedido = [];
        })
        .catch((err) => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          this.notificacion.tipo = "error";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.$bus.$emit("reload");
        });
    },
  },
};
</script>
<style scoped>
  td#acciones {
    min-width: 130px; 
  }
</style>