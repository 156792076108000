<template>
  <section>
    <alert
      v-bind:tipo="notificacion.tipo"
      v-bind:mensaje="notificacion.mensaje"
    ></alert>

    <v-card v-if="!showForm">
      <v-card-title>
        <div>
          <span class="headline">Observaciones:</span>
          <p>{{ tmpot0_observ }}</p>
          <v-btn @click="toggleForm" color="primary"
            >Modificar Observación
          </v-btn>
        </div>
      </v-card-title>
    </v-card>
    <v-card v-if="showForm">
      <v-form
        ref="formModObs"
        v-on:submit.prevent="modObs"
        v-show="notificacion.mensaje == ''"
      >
        <v-card-title>
          <label class="headline">Observaciones: </label>
        </v-card-title>
        <v-textarea
          :no-resize="true"
          outline
          name="input-7-4"
          v-model="tmpot0_observ"
          style="margin:0 15px"
        >
        </v-textarea>

        <v-btn color="alert" @click="toggleForm" dark>Cancelar</v-btn>
        <v-btn type="submit" color="primary" dark>Guardar</v-btn>
      </v-form>
    </v-card>
  </section>
</template>

<script>
import OrdenesTrabajoTemServices from "@/services/OrdenesTrabajoTemServices";
import alert from "@/components/alert/alert.vue";

export default {
  name: "modObservacion",
  components: { alert },
  props: {
    tmpot0_observ: {
      default: "",
    },
    showForm: {
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      notificacion: { mensaje: "", tipo: "error" },
      obsRules: [
        (v) => !!v || "La observación no puede ser vacía",
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters'
      ],
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    modObs() {
      //Verificamos que se cumplan las validaciones
      if (!this.$refs.formModObs.validate()) {
        return false;
      }
      this.notificacion.mensaje = "Actualizando...";
      this.notificacion.tipo = "info";
      OrdenesTrabajoTemServices.modObservacion(this.tmpot0_observ)
        .then(() => {
          this.notificacion.mensaje = "Observación actualizada correctamente";
          this.notificacion.tipo = "success";
          this.$bus.$emit("modObservacionSucess",this.tmpot0_observ)
          //Limpio las validaciones
          this.$refs.formModObs.resetValidation();
        })
        .catch((err) => {
          this.notificacion.mensaje =
            "Ocurrió un error al modificar la observación.";
          this.notificacion.tipo = "error";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.notificacion.mensaje = "";
            this.toggleForm();
          }, 4000);
        });
    },
  },
};
</script>
