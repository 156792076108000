<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
    <loading v-bind:dialog="isLoading"></loading>
    <template v-slot:activator="{ on }">
      <v-btn color="warning" fab small dark v-on="on" title="Actualizar Artículo">
        <v-icon>shopping_cart</v-icon>
      </v-btn>
    </template>
    <v-form 
      ref="form-updateItem" 
      v-on:submit.prevent="actualizar"
      v-show="!isLoading && notificacion.mensaje < 1"
    >
      <v-card>
        <v-card-title>Actualizar Artículo</v-card-title>
        <v-divider light></v-divider>
        <v-card-text>
          <v-text-field
            label="Cantidad"
            min="0" 
            type="number" 
            :rules="cantidadRules"
            v-model.number="articulo.tmpot1_cantid"
          ></v-text-field>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="dialog = false">Cancelar</v-btn>
          <v-btn type="submit" color="primary darken-1" flat>Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    
  </v-dialog>
</template>
<script>
import ODTTServices from "@/services/OrdenesTrabajoTemServices";
import loading from "@/components/share/Loading.vue";
import alert from "@/components/alert/alert.vue";

export default {
  name: "ActualizarItem",
  components: { loading, alert },
  data() {
    return {
      isLoading: false,
      dialog: false,
      notificacion: { mensaje: "", tipo: "error" },
      cantidadRules: [
        v => !!v || "Debe ingresar una cantidad mayor a cero.",
        v => v >= 0 || "Debe ingresar una cantidad mayor a cero."
      ],
    };
  },
  props: {
    articulo: {
      type: Object,
      required: true
    }
  },
  methods: {
    actualizar() {
      this.isLoading = true;
      ODTTServices.updateItem(this.articulo.tmpot1_item,this.articulo.tmpot1_cantid)
        .then(() => {
          this.notificacion.mensaje = "Artículo actualizado.";
          this.notificacion.tipo = "success";
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar actualizar el artículo.";
          this.notificacion.tipo = "error";
          if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
          setTimeout(() => {
            this.dialog = false;
            this.notificacion.mensaje = "";
            this.$bus.$emit('reload');
          }, 3000);
        });
    }
  }
};
</script>

<style scoped>
.v-card__actions .v-btn,
.v-card__actions > * {
  margin: 0;
  margin-right: 15px;
}
</style>